document.addEventListener('DOMContentLoaded', () => {
    const arBtnsCloseAppBanner = document.querySelectorAll('.js-app-banner-close');
    const oHeader = document.querySelector('header.header');

    arBtnsCloseAppBanner.forEach(btn => {
        btn.addEventListener('click', () => {
            btn.closest('.app-banner').classList.add('is-hide');

            oHeader.style.top = 0
        });
    })
})