import '%modules%/header/header';
import '%modules%/burger/burger';
import '%modules%/footer/footer';
import '%modules%/accordion/accordion';
import '%modules%/tabs/tabs';
import '%modules%/swiper/swiper';
import '%modules%/fav-btn/fav-btn';
import '%modules%/main-slider/main-slider';
import '%modules%/form-styler/form-styler';
import '%modules%/catalog/catalog';
import '%modules%/card/card';
import '%modules%/dadata/dadata';
import '%modules%/popups/popups';
import '%modules%/checkout/checkout';
import '%modules%/profile/profile';
import '%modules%/addresses/addresses';
import '%modules%/orders/orders';
import '%modules%/lookbook/lookbook';
import '%modules%/stores/one/store-one';
import '%modules%/auth-reg/auth-reg';
import '%modules%/page-about/page-about';
import '%modules%/tooltip/tooltip';
import '%modules%/app-banner/app-banner';
import '%modules%/cookie-popup/cookie-popup';

$(document).on('click', '[href="#"]', function (event) {
  event.preventDefault();
});

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

