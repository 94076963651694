document.addEventListener('DOMContentLoaded', () => {
    window.searchTimer = null;

    const sAjaxTipsUrl = '/local/ajax/getSearchTips.php';
    const sAjaxRemoveSearchQuery = '/local/ajax/removeSearchQuery.php';

    const oChangeEvent = new Event('change');
    const oSearchPopup = document.getElementById('search-popup');
    const oSearchInput = oSearchPopup.querySelector('[data-search-input]');
    const oSearchTips = oSearchPopup.querySelector('[data-search-tips]');
    const oSearchHistory = oSearchPopup.querySelector('[data-search-history]');
    const arSearchElements = oSearchPopup.querySelectorAll('[data-search-element]');
    const arSearchElementsDeleteBtns = oSearchPopup.querySelectorAll('[data-search-element-delete]');

    if (arSearchElements.length > 0) {
        arSearchElements.forEach(oElement => {
            oElement.addEventListener('click', () => {
                oSearchInput.value = oElement.innerText;
                oSearchInput.dispatchEvent(oChangeEvent);
            });
        });
    }

    if (arSearchElementsDeleteBtns.length > 0) {
        arSearchElementsDeleteBtns.forEach(oBtn => {
            oBtn.addEventListener('click', (oEvent) => {
                oEvent.stopPropagation();
                const listItem = oBtn.closest('li'),
                    sInputValue = listItem.querySelector('.search-history__item--text').textContent;

                window.BX.ajax({
                    url: sAjaxRemoveSearchQuery,
                    method: 'POST',
                    data: {
                        sessid: window.BX.bitrix_sessid(),
                        sQuery: sInputValue
                    },
                    dataType: 'json',
                    processData: true,
                    onsuccess: (oResponse) => {
                        if(oResponse.success)
                            listItem.remove();
                    }
                });
            });
        });
    }

    if (oSearchInput && oSearchTips) {
        ['input', 'change'].forEach(sType => {
            oSearchInput.addEventListener(sType, (oEvent) => {
                const { target: { value: sInputValue }, type: sEventType } = oEvent;

                let iRequestTipsTimer = 1000;

                if (sInputValue.length === 0) {
                    oSearchTips.style.display = 'none';
                    oSearchHistory.style.display = 'block';
                }

                if (sInputValue.length < 3 || (sEventType === 'change' && oEvent.bubbles))
                    return;

                if (sEventType === 'change') {
                    iRequestTipsTimer = 0;
                }

                if (!!window.searchTimer)
                    clearTimeout(window.searchTimer);

                window.searchTimer = setTimeout(() => {
                    window.BX.ajax({
                        url: sAjaxTipsUrl,
                        method: 'POST',
                        data: {
                            sessid: window.BX.bitrix_sessid(),
                            sQuery: sInputValue
                        },
                        dataType: 'json',
                        processData: true,
                        onsuccess: (oResponse) => {
                            const { success: bSuccess, searchTitles: oSearchTitles } = oResponse;
                            oSearchTips.innerHTML = '';

                            if (bSuccess && oSearchTitles) {
                                const oSearchTipsList = document.createElement('ul');

                                oSearchTipsList.classList.add('search-tips__list');

                                for (let iKey in oSearchTitles) {
                                    const sSearchTitle = oSearchTitles[iKey];
                                    const oTipLiNode = document.createElement('li');
                                    const oTipButtonNode = document.createElement('button');
                                    const oTipSpanNode = document.createElement('span');

                                    oTipButtonNode.classList.add('search-tips__item');
                                    oTipSpanNode.classList.add('search-tips__item--text');
                                    oTipSpanNode.innerText = sSearchTitle;

                                    oTipButtonNode.addEventListener('click', () => {
                                        oSearchInput.value = oTipButtonNode.innerText;
                                        oSearchInput.dispatchEvent(oChangeEvent);
                                    });

                                    oTipButtonNode.append(oTipSpanNode);
                                    oTipLiNode.append(oTipButtonNode);

                                    oSearchTipsList.append(oTipLiNode);
                                }

                                oSearchTips.append(oSearchTipsList);

                                oSearchTips.style.display = 'block';

                                if (oSearchHistory)
                                    oSearchHistory.style.display = 'none';

                            } else {
                                oSearchTips.style.display = 'none';

                                if (oSearchHistory)
                                    oSearchHistory.style.display = 'block';
                            }
                        },
                        onfailure: () => { },
                    });
                }, iRequestTipsTimer);
            });
        });
    }

});